<template>
  <div class="level-list-page w-100">
    <basic-subheader v-if="isWritePermission">
      <template v-slot:actions>
        <b-button class="btn btn-success" type="button" @click="createLevel">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm cấp độ cao hơn
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        @sortBy="sortRequest"
      >
        <template v-slot:body="{ item }">
          <td>{{ item.code }}</td>
          <td class="text-center">{{ item.name }}</td>
          <td class="text-center">{{ formatDate(item.updateDate) }}</td>
          <td>
            <div class="d-flex align-items-center">
              <avatar
                size="40px"
                :text="item.updaterName"
                :src="item.updaterImage"
                :rounded="true"
              />
              <div class="d-flex flex-column ml-5">
                <p class="mb-0 module-list-page__body__updater-name">
                  {{ item.updaterName }}
                </p>
                <p class="mt-2 mb-0 module-list-page__body__updater-code">
                  {{ item.updaterUsername }}
                </p>
              </div>
            </div>
          </td>
        </template>
      </template-table>
    </Wrapper>
    <ModalLevel v-if="showModal" @reset="resetModal" @submit="submitModal" />
  </div>
</template>
<script>
import { SET_MODAL } from '@/core/services/store/context.module';
export default {
  name: 'levelList',
  components: {
    ModalLevel: () => import('./components/Modal.vue'),
  },
  data() {
    return {
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
          style: {
            width: '8%',
          },
        },
        {
          key: 'name',
          label: 'Cấp độ',
          sortable: true,
          style: {
            width: '12%',
          },
          class: 'sort-center',
        },
        {
          key: 'updateDate',
          label: 'Cập nhật lần cuối',
          sortable: false,
          class: 'text-center',
        },

        {
          key: 'updaterName',
          label: 'Người cập nhật',
          sortable: false,
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
      data: [],
      showModal: false,
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('/LessonLevel', {
          params: this.searchParams,
        })
        .then((res) => {
          let data = res.data.items;
          this.data = data.map((item) => {
            return {
              ...item,
              updaterImage: item.updaterImage?.url,
            };
          });
          this.paging.total = res.data.total;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    createLevel() {
      this.showModal = true;
      this.$store.commit(`context/${SET_MODAL}`, true);
    },
    resetModal() {
      this.showModal = false;
      this.levelId = null;
      this.$store.commit(`context/${SET_MODAL}`, false);
    },
    submitModal() {
      this.loadData();
    },
  },
};
</script>
<style lang="scss">
.sort-center {
  .sorting {
    justify-content: center;
  }
}
</style>
